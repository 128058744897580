import React from 'react';
import PageLayout from '@containers/PageLayout';
import Section from '@containers/Section';

const NowPage = () => (
  <PageLayout title="Now" description="Steven Sajja — Now">
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-screen-xl mx-auto px-4 lg:pl-0 lg:px-8 sm:px-6">
        <div className="mx-auto text-base lg:max-w-none">
          <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Now
          </h1>
        </div>
        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
          <div>
            <div className="text-base mx-auto lg:max-w-none">
              <p className="text-lg leading-7 text-gray-500 mb-5">
                Writing code, leading product and managing the great team{' '}
                <a
                  href="https://www.picortex.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  building PiCortex.
                </a>{' '}
                <br />— Our mission is to simplify the lives of small to medium
                sized enterprise owners & operators by providing products that
                help them run & orchestrate their businesses.
              </p>
            </div>
            <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg leading-7 text-gray-500 my-5">
                Consulting on niche business and technical challenges with{' '}
                <a
                  href="https://boragrowth.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bora Growth Partners.
                </a>{' '}
                <br />— We partner with startups to Fortune 100 companies to
                transform their businesses using innovation to make their
                experiences a reality.
              </p>
              <p className="text-lg leading-7 text-gray-500 mb-5">
                Sharing my entrepreneurship journey in{' '}
                <a
                  href="https://stevensajja.substack.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  my newsletter.
                </a>{' '}
              </p>
              <p className="text-lg leading-7 text-gray-500 my-5">
                Staying healthy and living in Cape Town, operating worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
);

export default NowPage;
